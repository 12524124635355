@tailwind base;
@tailwind components;
@tailwind utilities;


.two-line-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-bottom-left {
    z-index: auto !important;
}

html {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}